import { IconProps } from "./types";

const AirdropWalletIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.616 20C4.15533 20 3.771 19.846 3.463 19.538C3.155 19.23 3.00067 18.8453 3 18.384V5.616C3 5.15533 3.15433 4.771 3.463 4.463C3.77167 4.155 4.156 4.00067 4.616 4H17.385C17.845 4 18.2293 4.15433 18.538 4.463C18.8467 4.77167 19.0007 5.156 19 5.616V7H12.616C11.8167 7 11.181 7.23633 10.709 7.709C10.2363 8.181 10 8.81667 10 9.616V14.385C10 15.1837 10.2363 15.819 10.709 16.291C11.181 16.7637 11.8167 17 12.616 17H19V18.385C19 18.845 18.846 19.229 18.538 19.537C18.23 19.845 17.8453 19.9993 17.384 20H4.616ZM12.616 16C12.1553 16 11.771 15.846 11.463 15.538C11.155 15.23 11.0007 14.8457 11 14.385V9.615C11 9.155 11.1543 8.771 11.463 8.463C11.7717 8.155 12.156 8.00067 12.616 8H18.385C18.845 8 19.2293 8.15433 19.538 8.463C19.8467 8.77167 20.0007 9.156 20 9.616V14.385C20 14.845 19.846 15.2293 19.538 15.538C19.23 15.8467 18.8457 16.0007 18.385 16H12.616ZM15 13.5C15.4333 13.5 15.7917 13.3583 16.075 13.075C16.3583 12.7917 16.5 12.4333 16.5 12C16.5 11.5667 16.3583 11.2083 16.075 10.925C15.7917 10.6417 15.4333 10.5 15 10.5C14.5667 10.5 14.2083 10.6417 13.925 10.925C13.6417 11.2083 13.5 11.5667 13.5 12C13.5 12.4333 13.6417 12.7917 13.925 13.075C14.2083 13.3583 14.5667 13.5 15 13.5Z"
				fill="white"
			/>
		</svg>
	);
};

export default AirdropWalletIcon;
