import { useI18n } from "@/locales/client";

import Coin from "@/components/ui/Coin";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
import HourglassIcon from "@/components/icons/HourglassIcon";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { createFileRoute } from "@tanstack/react-router";
import TelegramWallet from "@/src/components/airdrop/TelegramWallet";

import { TonConnectUIProvider } from "@tonconnect/ui-react";

const Airdrop = () => {
	const t = useI18n("airdrop");

	if (!t) {
		return <ScreenLoader />;
	}

	const content = [
		{ text: t("withExchanges"), completed: true },
		{ text: t("withMarketMakers"), completed: false },
		{ text: t("key"), completed: false },
		{ text: t("taskList"), completed: false },
	];

	return (
		<TonConnectUIProvider manifestUrl="https://app-dev.ocean-kombat.com/tonconnect-manifest.json">
			<main className="h-screen flex flex-col items-center gap-8 pt-16 px-6">
				<Coin className="w-[120px] h-[120px] transition ease-in-out animate-in duration-300 slide-in-from-top fade-in-0" />

				<h1 className="text-[28px] text-center font-semibold leading-8">
					{t("getReady")}
				</h1>

				<div className="flex flex-col gap-2 self-stretch">
					<TelegramWallet />
					{content.map((item) => (
						<Item key={item.text} {...item} />
					))}
				</div>
			</main>
		</TonConnectUIProvider>
	);
};

const Item = ({ text, completed }: { text: string; completed: boolean }) => {
	return (
		<div className="flex items-center px-4 py-[15px] gap-3 border border-gray-300 rounded-xl bg-gradient-to-b from-gray-500 to-black">
			{completed ? (
				<CheckCircleIcon className="w-6 h-6 text-positive" />
			) : (
				<HourglassIcon className="w-6 h-6 text-gray-200" />
			)}

			<p className="font-semibold">{text}</p>
		</div>
	);
};

export const Route = createFileRoute("/airdrop/")({
	component: Airdrop,
});
